/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeBlock } from '../../components/code-block';

import proxyMode from './code-blocks/proxy-mode';
import proxyModeExamples from './code-blocks/proxy-mode-examples';

const ProxyMode = () => {
  return (
    <section id="proxy-mode">
      <h2>
        Proxy Mode <sup>Beta</sup>
      </h2>

      <p>ScrapeOwl can be used just like any other proxies.</p>

      <p>When used in proxy mode, the data parsing (elements) feature is not usable because you are no longer receiving JSON responses.
      </p>

      <p>
        To use ScrapeOwl as a proxy, copy the following code, and set your API
        key.
      </p>

      <h3>API parameters example</h3>
      <CodeBlock codes={proxyMode} />

      <h3>Code example</h3>
      <CodeBlock codes={proxyModeExamples} />
    </section>
  );
};

export default ProxyMode;
