const firstResponse = `{
  "status": 200,
  "is_billed": true,
  "credits": {
    "available": 0,
    "used": 0,
    "request_cost": 0
  },
  "html": "{\\n  \\"origin\\": \\"98.118.113.251\\"\\n}\\n"
}`;

export default firstResponse;
