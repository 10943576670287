/** @jsx jsx */
import { jsx } from 'theme-ui';

const Credits = () => {
  return (
    <section>
      <div sx={{ mt: 5 }}>
        <div className="alert">
          <strong>
            If you have any questions, reach out to{' '}
            <a href="mailto:support@scrapeowl.com">support@scrapeowl.com</a>,
            and we will be more than happy to help you out.
          </strong>
        </div>
      </div>
    </section>
  );
};

export default Credits;
