/*
export default `{
  "api_key": "YOUR_API_KEY", // Your API key you generated from the dashboard goes here
  "url": "https://httpbin.org/ip", // The site you want to scrape and parse
}`
*/
const proxyModeExamples = [
  {
    lang: 'curl',
    format: 'curl',
    code: `curl -x "http://scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000" -k "https://httpbin.org/ip"`,
  },
  {
    lang: 'node',
    format: 'javascript',
    code: `const got = require('got');
const { HttpsProxyAgent } = require("hpagent");

(async () => {
  try {
    const response = await got("https://httpbin.org/ip", {
      agent: {
        https: new HttpsProxyAgent({
          proxy:
            "http://scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000",
        }),
      },
      https: {
        rejectUnauthorized: false,
      },
    });
    console.log(response.body);
  } catch (error) {
    console.log(error.message);
  }
})();`,
  },
  {
    lang: 'php',
    format: 'php',
    code: `<?php
  $ch = curl_init( "https://httpbin.org/ip" );

  curl_setopt($ch, CURLOPT_PROXY, 'scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000');
  curl_setopt($ch, CURLOPT_SSL_VERIFYHOST, 0);
  curl_setopt($ch, CURLOPT_SSL_VERIFYPEER, 0);

  // Return response instead of printing.
  curl_setopt( $ch, CURLOPT_RETURNTRANSFER, true );

  // Send request.
  $result = curl_exec($ch);

  curl_close($ch);

  // Print response.
  echo $result;
?>`,
  },
  {
    lang: 'python',
    format: 'python',
    code: `import requests

#API details
url_to_scrape = "https://httpbin.org/ip"
  
#ScrapeOwl proxy
proxies = {
  "http": "http://scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000",
  "https": "http://scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000",
}

#Making http GET request
response = requests.get(url_to_scrape, proxies=proxies, verify=False)

#Print the response from site
print(response.text)`,
  },
];

export default proxyModeExamples;
