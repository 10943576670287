/** @jsx jsx */
import { jsx } from "theme-ui";
import { CodeBlock, CodeResponseBlock } from "../../components/code-block";
import firstRequest from "./code-blocks/first-request";
import firstResponse from "./code-blocks/first-response";

const MakingYourFirstRequest = () => {
  return (
    <section id="making-your-first-request">
      <h2>Making Your First Request</h2>
      <p>
        APIs are consumed programmatically, meaning you write a program to get
        the data you want from websites you want to scrape and parse.
      </p>
      <p>
        To demonstrate the power of the ScrapeOwl use the example below to
        return a test query using Javascript Object Notation (JSON) to make a
        request to the API.
      </p>

      <h3>Code example</h3>
      <CodeBlock codes={firstRequest} />

      <h3>Result</h3>
      <CodeResponseBlock codeText={firstResponse} />

      <h3>The following table lists all available ScrapeOwl API parameters</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="badge" variant="light">
                  api_key
                </span>
              </td>
              <td>string</td>
              <td>required</td>
              <td>Your ScrapeOwl API Key</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  url
                </span>
              </td>
              <td>string</td>
              <td>required</td>
              <td>Target URL to scrape</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  elements
                </span>
              </td>
              <td>array</td>
              <td>optional</td>
              <td>List of elements to extract from the webpage</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  html
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>Return page's HTML if set to true. Defaults to false</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  return_headers
                </span>
              </td>
              <td>boolean</td>
              <td>false</td>
              <td>Return headers returned by the target website server</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  return_cookies
                </span>
              </td>
              <td>boolean</td>
              <td>false</td>
              <td>Return cookies returned by the target website server</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  cookies
                </span>
              </td>
              <td>string</td>
              <td>optional</td>
              <td>Cookies headers to send to target URL</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  headers
                </span>
              </td>
              <td>array</td>
              <td>optional</td>
              <td>HTTP headers to send to target URL</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  request_method
                </span>
              </td>
              <td>string</td>
              <td>GET</td>
              <td>
                GET, POST, PUT. Default is GET but if you want to POST/PUT data
                then use appropriate value.
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  post_data
                </span>
              </td>
              <td>string | array</td>
              <td>optional</td>
              <td>POST data on the page</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  premium_proxies
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>Use residential proxies to scrape</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  country
                </span>
              </td>
              <td>string</td>
              <td>optional</td>
              <td>Set source IP country for residential proxy</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  render_js
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>Use headless chrome instance to execute JavaScript</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  custom_js
                </span>
              </td>
              <td>string</td>
              <td>optional</td>
              <td>
                Send custom JavaScript to run on the page prior to extracting
                the HMTL of the page
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  wait_for
                </span>
              </td>
              <td>string</td>
              <td>optional</td>
              <td>
                Specify an element that we should wait for to load before
                scraping the page or an amount time in ms.
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  reject_requests
                </span>
              </td>
              <td>array</td>
              <td>optional</td>
              <td>Block requests on page</td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  json_response
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>
                Default is true. If it’s set to false, it will return html
                results instead of JSON, also useful for downloading files.
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  screenshot
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>
                Default is false, screenshot only works with{" "}
                <span className="badge" variant="light">
                  render_js
                </span>
                . It is useful for debugging and shows you the full page as seen
                by our API.
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge" variant="light">
                  block_resources
                </span>
              </td>
              <td>boolean</td>
              <td>optional</td>
              <td>
                Default is true. When running{" "}
                <span className="badge" variant="light">
                  render_js
                </span>{" "}
                we block CSS, Images, and fonts by default. This can break some
                sites (sites using React for example). When set to false, the
                API will load the page without blocking any resources i.e
                exactly as a page is loaded in a normal browser.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default MakingYourFirstRequest;
