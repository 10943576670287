/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeResponseBlock } from '../../components/code-block';
import customJavascript from './code-blocks/custom-javascript';

const RunningCustomJavascript = () => {
  return (
    <section id="running-custom-javascript">
      <h2>Executing Custom JavaScript on the webpage</h2>

      <p>
        You can pass custom JavaScript to ScrapeOwl using the{' '}
        <span className="badge">custom_js</span> attribute, the custom
        JavaScript is executed on the page's console before capturing the
        content.
      </p>
      <p>
        When testing make sure that the JavaScript snippet executes on your
        browser's console, as ScrapeOwl executes the code in the same
        environment.
      </p>
      <p>
        For example, by using the following JavaScript we can scroll the page to
        the bottom of the page.
      </p>

      <CodeResponseBlock codeText={customJavascript} />

      <div className="alert">
        <strong>Note:</strong> Because you can not pass a multi-line string in a
        JSON object, please remove all white spaces and convert it to single
        line before sending.
      </div>
    </section>
  );
};

export default RunningCustomJavascript;
