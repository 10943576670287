/** @jsx jsx */
import { jsx } from 'theme-ui';

const GettingStarted = () => {
  return (
    <section id="getting-started">
      <h2 className="section-title">Getting Started</h2>
      <p>
        The first step before you can start using the ScrapeOwl API is signing
        up and creating an account by going to the{' '}
        <a href="https://app.scrapeowl.com/register">registration page</a> or
        logging into the{' '}
        <a href="https://app.scrapeowl.com/dashboard">dashboard</a> if you
        already have an account.
      </p>

      <p>
        Once you're in, your API key should be visible on the dashboard where
        you can simply copy and paste it to your requests. It is a long string
        (80 characters) that is a series of random numbers and letters that
        looks something like this:
      </p>

      <code>
        9ijf24fk93rg038jg30rigj394f34f0kh12d12ep3fp24gk3pgk34g23gf74fl430913fj2133f32ffj
      </code>

      <h3>Api endpoint</h3>
      <p>The base URL for our scraping API is:</p>
      <strong>https://api.scrapeowl.com/v1/scrape</strong>

      <div className="alert">
        <strong>Note: All response are in JSON formatted output by default. If you want to disable this behavior and return HTML instead, set <span className="badge">json_response</span> to false.</strong>
      </div>
    </section>
  );
};

export default GettingStarted;
