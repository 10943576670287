/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeResponseBlock } from '../../components/code-block';

import waitForTime from './code-blocks/wait-for-time';
import waitForElement from './code-blocks/wait-for-element';

const WaitingForElements = () => {
  return (
    <section id="waiting-for-elements">
      <h2>Waiting for Elements to Load</h2>
      <p>
        <span className="badge">wait_for</span> can be used to make ScrapeOwl
        wait for a given amount of time (in milliseconds) before capturing the
        elements or page's HTML as shown in the following example.
      </p>
      <CodeResponseBlock codeText={waitForTime} />

      <p>
        The above code waits for the duration of 6000ms (6 seconds) before
        capturing the elements or page's HTML.
      </p>

      <div className="alert">
        <strong>Note:</strong>
        <span className="badge">timeout</span> set for each element will
        override above value.
      </div>

      <p>
        The <span className="badge">wait_for</span> can also be used to wait for
        an element to load on the page
      </p>

      <CodeResponseBlock codeText={waitForElement} />

      <p>
        The above will wait for all of the p tag elements on the page to load
        before extracting data from the page.
      </p>
      <div className="alert alert-custom">
        <strong>Note:</strong>
        <span className="badge">wait_for</span> only works when{' '}
        <span className="badge">render_js</span> is set to true
      </div>
    </section>
  );
};

export default WaitingForElements;
