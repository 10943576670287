/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeBlock, CodeResponseBlock } from '../../components/code-block';
import parsingRequest from './code-blocks/parsing-request';
import parsingResponse from './code-blocks/parsing-response';

const Parsing = () => {
  return (
    <section id="parsing">
      <h2>Extracting Custom Elements</h2>

      <p>
        ScrapeOwl's killer feature is that it's able to extract data from pages,
        so you do not need to parse the page's HTML yourself. Below is a table
        detailing the selection fields.
      </p>

      <h3>ScrapeOwl API Element Selection</h3>

      <div className="table-container">
        <table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Default</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <span className="badge">type</span>
              </td>
              <td>string</td>
              <td>css</td>
              <td>
                Specify XPath if you are trying to extract using XPath. The
                default is CSS
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge">selector</span>
              </td>
              <td>string</td>
              <td>required</td>
              <td>
                XPath or css selector for the element you are trying to extract.
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge">timeout</span>
              </td>
              <td>integer</td>
              <td>optional</td>
              <td>
                Time in milliseconds to wait for dynamic elements to load on the
                page
              </td>
            </tr>
            <tr>
              <td>
                <span className="badge">html</span>
              </td>
              <td>boolean</td>
              <td>false</td>
              <td>Return the inner HTML of the element</td>
            </tr>
            <tr>
              <td>
                <span className="badge">attributes</span>
              </td>
              <td>boolean | array</td>
              <td>false</td>
              <td>Return the attributes of the element.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="alert">
        The <span className="badge">timeout</span> parameter only works when{' '}
        <span className="badge">render_js</span> is set to true
      </div>

      <h3>Code example</h3>
      <CodeBlock codes={parsingRequest} />

      <p>
        The above JSON code shows the request containing element attributes with
        all potential values. The first uses XPath as a selector and the second
        uses the CSS selector. The CSS selector is essentially your HTML element
        name, class, or ID. It's what you would write in your stylesheet (CSS).
      </p>

      <h3>Element extraction result</h3>
      <CodeResponseBlock codeText={parsingResponse} />

      <p>
        This is an example of a response when extracting all p tags on
        https://example.com where the API extracts all elements that match the
        given conditions. The response contains your data as objects where each
        object contains the text, HTML, or attributes of each element.
      </p>
      <p>
        Attributes are key and value pairs of HTML attributes like class or any
        custom data-* attributes set to the particular elements on the page.
      </p>
    </section>
  );
};

export default Parsing;
