/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeResponseBlock } from '../../components/code-block';
import customHeaders from './code-blocks/custom-headers';

const HttpHeaders = () => {
  return (
    <section id="http-headers">
      <h2>Setting Custom HTTP Headers</h2>

      <p>
        The <span className="badge">headers</span> attribute is an object
        containing all of the headers that you want to send to the target URL.
        You can easily set custom HTTP headers to pass along with your requests
        as follows:
      </p>

      <CodeResponseBlock codeText={customHeaders} />

      <p>
        The above code example sends "Accept Language" HTTP header to all
        outgoing requests.
      </p>
    </section>
  );
};

export default HttpHeaders;
