/** @jsx jsx */
import { jsx } from 'theme-ui';

const RequestCalculation = () => {
  return (
    <section id="request-calculation">
      <h2>API Request Cost Calculation</h2>

      <p>
        Because ScrapeOwl has multiple features, the credit consumption per
        request depends on the specifics of your request and which attributes
        you're sending along.
      </p>

      <p>
        The <span className="badge">request_cost</span> attribute in the
        response shows the cost in credit that the request costed.
      </p>

      <h3>Credit consumption per request type is as follows</h3>

      <div className="table-container">
        <table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Description</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                A request using rotating proxies without{' '}
                <span className="badge">render_js</span>
              </td>
              <td>
                <strong>1</strong> credit
              </td>
            </tr>
            <tr>
              <td>
                A request using rotating proxies with{' '}
                <span className="badge">render_js</span>
              </td>
              <td>
                <strong>5</strong> credits
              </td>
            </tr>
            <tr>
              <td>
                A request using premium proxies without{' '}
                <span className="badge">render_js</span>
              </td>
              <td>
                <strong>10</strong> credits
              </td>
            </tr>
            <tr>
              <td>
                A request using premium proxies with{' '}
                <span className="badge">render_js</span>
              </td>
              <td>
                <strong>25</strong> credits
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default RequestCalculation;
