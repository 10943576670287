const usageEndpointResponse = `{
  "credits": 1000,
  "credits_used": 600,
  "requests": 510,
  "failed_requests": 0,
  "successful_requests": 510,
  "concurrency_limit": 1
}`;

export default usageEndpointResponse;
