const parsingRequest = [
  {
    lang: "json",
    format: "json",
    code: `{
  "api_key": "YOUR_API_KEY", // Your API key you generated from the dashboard goes here
  "url": "https://example.com", // The site you want to scrape and parse

  //After you've provided your API key and the URL of the site you wish to parse, 
  "elements" : [ 
    {
      "type": "xpath",
      "selector": "//p"
    },
    {
      "type": "css",
      "selector": "p"
    }
  ]
}`,
  },
  {
    lang: "curl",
    format: "curl",
    code: `curl \\
  -H "Content-Type: application/json" \\
  -d '{"api_key": "YOUR_API_KEY", "url": "https://example.com", "elements" : [ {"type": "xpath", "selector": "//p"}, {"type": "css", "selector": "p"} ]}' \\
  -X POST https://api.scrapeowl.com/v1/scrape`,
  },
  {
    lang: "node",
    format: "javascript",
    code: `const axios = require('axios')

;(async () => {
  try {
    const response = await axios.post('https://api.scrapeowl.com/v1/scrape', {
      api_key: 'YOUR_API_KEY',
      url: 'https://example.com',
      elements: [
        {
          type: 'xpath',
          selector: '//p',
        },
        {
          type: 'css',
          selector: 'p',
        },
      ],
    })

    console.log(response.data)
  } catch (e) {
    console.log(e.message)
  }
})()
`,
  },
  {
    lang: "php",
    format: "php",
    code: `<?php
  $ch = curl_init( "https://api.scrapeowl.com/v1/scrape" );

  $headers = array(
    "Content-Type: application/json",
  );

  // Setup request to send json via POST.
  $array_of_data = array(
    "api_key" => "YOUR_API_KEY",
    "url" => "https://example.com",
    "elements" => array(
      array(
        "type" => "xpath",
        "selector" => "//p"
      ),
      array(
        "type" => "css",
        "selector" => "p"
      )
    )
  );

  $payload = json_encode( $array_of_data );

  curl_setopt( $ch, CURLOPT_POSTFIELDS, $payload );

  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

  // Return response instead of printing.
  curl_setopt( $ch, CURLOPT_RETURNTRANSFER, true );

  // Send request.
  $result = curl_exec($ch);

  curl_close($ch);

  // Print response.
  echo $result;
?>`,
  },
  {
    lang: "python",
    format: "python",
    code: `import requests

#API details
scrapeowl_url = "https://api.scrapeowl.com/v1/scrape"

#Object of the request
object_of_data = {
  "api_key": "YOUR_API_KEY",
  "url": "https://example.com",
  "elements": [
    {
      "type": "xpath",
      "selector": "//p"
    },
    {
      "type": "css",
      "selector": "p"
    }
  ]
}

#Making http post request
response = requests.post(scrapeowl_url, json=object_of_data)

#Print the JSON response from API
print(response.json())`,
  },
  {
    lang: "ruby",
    format: "ruby",
    code: `require 'net/http'
require 'uri'
require 'json'

#API details
scrapeowl_uri = URI.parse("http://api.scrapeowl.com/v1/scrape")

#Object of the request
object_of_data = {
  api_key: "YOUR_API_KEY",
  url: "https://example.com",
  elements: [
    {
      type: "xpath",
      selector: "//p"
    },
    {
      type: "css",
      selector: "p"
    }
  ]
}

#Create the HTTP objects
http = Net::HTTP.new(scrapeowl_uri.host, scrapeowl_uri.port)
request = Net::HTTP::Post.new(scrapeowl_uri.request_uri)
request.body = object_of_data.to_json

#Making http post request
response = http.request(request)

#Print the JSON response from API
puts JSON.parse(response.body)`,
  },
];

export default parsingRequest;
