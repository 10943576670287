const postFormResponse = [
  {
    lang: "json",
    format: "json",
    code: `{
  "api_key": "YOUR_API_KEY", // Your API key you generated from the dashboard goes here
  "url": "https://httpbin.org/anything", // The site you want to scrape and parse
  "request_method": "POST",
  "post_data": "name=value"
}`,
  },
  {
    lang: "curl",
    format: "curl",
    code: `curl \\
  -H "Content-Type: application/json" \\
  -d '{"api_key": "YOUR_API_KEY", "url": "https://httpbin.org/post", "request_method": "POST", "post_data": "name=value"}' \\
  -X POST https://api.scrapeowl.com/v1/scrape`,
  },
  {
    lang: "node",
    format: "javascript",
    code: `const axios = require('axios')
const queryString = require('query-string')

;(async () => {
  try {
    const response = await axios.post('https://api.scrapeowl.com/v1/scrape', {
      api_key: 'YOUR_API_KEY',
      url: 'https://httpbin.org/post',
      request_method: 'POST',
      request_method: 'POST',
      post_data: queryString.stringify({
        name: 'value',
      }),
    })

    console.log(response.data.html)
  } catch (e) {
    console.log(e.message)
  }
})()
`,
  },
  {
    lang: "php",
    format: "php",
    code: `<?php
  $ch = curl_init( "https://api.scrapeowl.com/v1/scrape" );

  $headers = array(
    "Content-Type: application/json",
  );

  $form_data = array(
    "name" => "value"
  );

  // Setup request to send json via POST.
  $array_of_data = array(
    "api_key" => "YOUR_API_KEY",
    "url" => "https://httpbin.org/post",
    "request_method" => "POST",
    "post_data" => http_build_query($form_data)
  );

  $payload = json_encode( $array_of_data );

  curl_setopt( $ch, CURLOPT_POSTFIELDS, $payload );

  curl_setopt($ch, CURLOPT_HTTPHEADER, $headers);

  // Return response instead of printing.
  curl_setopt( $ch, CURLOPT_RETURNTRANSFER, true );

  // Send request.
  $result = curl_exec($ch);

  curl_close($ch);

  // Print response.
  echo $result;
?>`,
  },
  {
    lang: "python",
    format: "python",
    code: `import requests
from urllib.parse import urlencode

#API details
scrapeowl_url = "https://api.scrapeowl.com/v1/scrape"

#Object of the data to POST as form data
object_of_form_data = {
  "name": "value"
}

#Object of the request
object_of_data = {
  "api_key": "YOUR_API_KEY",
  "url": "https://httpbin.org/post",
  "request_method": "POST",
  "post_data": urlencode(object_of_form_data)
}

#Making http post request
response = requests.post(scrapeowl_url, json=object_of_data)

#Print the JSON response from API
print(response.json())`,
  },
  {
    lang: "ruby",
    format: "ruby",
    code: `require 'net/http'
require 'uri'
require 'json'

#API details
scrapeowl_uri = URI.parse("https://api.scrapeowl.com/v1/scrape")

#Object of form data
object_of_form_data = {
  name: "value"
}

#Object of the request
object_of_data = {
  api_key: "YOUR_API_KEY",
  url: "https://httpbin.org/post",
  request_method: "POST",
  post_data: URI.encode_www_form(object_of_form_data)
}

#Create the HTTP objects
http = Net::HTTP.new(scrapeowl_uri.host, scrapeowl_uri.port)
request = Net::HTTP::Post.new(scrapeowl_uri.request_uri)
request.body = object_of_data.to_json

#Making http post request
response = http.request(request)

#Print the JSON response from API
puts response.body`,
  },
];

export default postFormResponse;
