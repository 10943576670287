/** @jsx jsx */
import { jsx } from "theme-ui";
import { CopyBlock, atomOneLight, dracula } from "react-code-blocks";
import { useState } from "react";

const CodeBlock = ({ codes }) => {
  const [code, setCode] = useState(codes[0]);

  const isActive = (c) => {
    return code.code === c;
  };

  return (
    <div sx={styles.wrapper}>
      <div sx={styles.languageButtons}>
        {codes.map((codeObject) => {
          return (
            <button
              key={codeObject.code}
              onClick={() => setCode(codeObject)}
              className={isActive(codeObject.code) ? "active" : null}
            >
              {codeObject.lang}
            </button>
          );
        })}
      </div>
      <div sx={styles.codeBlockWrapper}>
        <CopyBlock
          wrapLines
          text={code.code}
          language={code.format}
          theme={dracula}
        />
      </div>
    </div>
  );
};

const CodeResponseBlock = ({ codeText }) => {
  return (
    <div sx={styles.wrapper}>
      <div sx={styles.codeBlockWrapper}>
        <CopyBlock wrapLines text={codeText} language="json" theme={dracula} />
      </div>
    </div>
  );
};

export { CodeResponseBlock, CodeBlock };

const styles = {
  wrapper: {
    border: "1px solid #e8e8e8",
    borderRadius: "8px",
    my: 3,
  },

  languageButtons: {
    display: "flex",
    bg: "#F0F0FD",
    borderBottom: "1px solid #e8e8e8",

    ".active": {
      bg: "#777DF2",
      color: "#FFFFFF",
    },

    button: {
      color: "purplegray",
      textTransform: "uppercase",
      fontSize: "0.8rem",
      px: 3,
      py: 1,
      m: 0,
      bg: "transparent",
      outline: "none",
      border: "none",
      cursor: "pointer",
    },
  },

  codeBlockWrapper: {
    "& > div": {
      overflowX: "auto",
      overflowY: "hidden",
    },

    "& > div::-webkit-scrollbar": {
      width: "8px",
      height: "8px",
    },

    "& > div::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
      bg: "#F5F5F5",
    },

    "& > div::-webkit-scrollbar-thumb": {
      bg: "purplegray",
      border: "2px solid #555555",
    },

    button: {
      outline: "none",
      border: "none",
      p: 2,
      bg: "transparent",
      cursor: "pointer",

      svg: {
        fill: "#fff",
      },
    },
  },
};
