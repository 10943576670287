/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeBlock, CodeResponseBlock } from '../../components/code-block';

import postFormDataRequest from './code-blocks/post-form-data';
import postFormDataResponse from './code-blocks/post-form-data-response';

const SendingPostRequests = () => {
  return (
    <section id="sending-post-requests">
      <h2>Posting data</h2>

      <p>
        If you want to send an HTTP POST/PUT request, then set{' '}
        <span className="badge">request_method</span> to POST or PUT.
      </p>

      <p>
        If you also want to send data with the request, then you can set your
        data in <span className="badge">post_data</span> attribute.
      </p>

      <p>
        When posting data, we try to detect the data type and set an appropriate
        Content-Type header.
      </p>
      <p>
        We set application/json if you set the{' '}
        <span className="badge">render_js</span> as an array or object.
      </p>
      <p>
        We set application/x-www-form-urlencoded if you set the{' '}
        <span className="badge">render_js</span> as a string.
      </p>
      <p>
        The Content-Type: application/x-www-form-urlencoded header makes the
        posted data as a normal form submit.
      </p>
      <p>The following code example uses POST request to send form data:</p>

      <h3>Code example</h3>
      <CodeBlock codes={postFormDataRequest} />

      <h3>Result</h3>
      <CodeResponseBlock codeText={postFormDataResponse} />

      <div className="alert alert-custom">
        <strong>Note:</strong> Above response example is actually parsed from
        ScrapeOwl’s response
      </div>
    </section>
  );
};

export default SendingPostRequests;
