/** @jsx jsx */
import { jsx } from "theme-ui";
import { CodeResponseBlock } from "../../components/code-block";
import sessionsExample from "./code-blocks/sessions-example";

const Sessions = () => {
  return (
    <section id="sessions">
      <h2>Sessions</h2>
      <p>
        You can re-use the same IP address for multiple requests using the
        sticky sessions feature. In order to make sure that the IP address is
        sticky and does not change, you need to set a value for the session when
        supplying the request. For example, “session”: “123456” in the first
        request and if you want to use the same IP, make sure the session value
        is the same as the prior request.
      </p>
      <p>
        There is no limit on the number of sessions you can have at a given
        time. However, the premium_proxies parameter needs to be set to “true”
      </p>

      <CodeResponseBlock codeText={sessionsExample} />
    </section>
  );
};

export default Sessions;
