const postFormDataResponse = `{
  "args":{
  },
  "data":"",
  "files":{
  },
  "form":{
    "name":"value"
  },
  "headers":{
    "Accept":"text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3",
    "Accept-Encoding":"gzip, deflate, br",
    "Accept-Language":"en-US",
    "Cache-Control":"no-cache",
    "Content-Length":"27",
    "Content-Type":"application/x-www-form-urlencoded",
    "Host":"httpbin.org",
    "Pragma":"no-cache",
    "Upgrade-Insecure-Requests":"1",
    "User-Agent":"Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/75.0.3770.100 Safari/537.36",
    "X-Amzn-Trace-Id":"Root=1-5f760c71-78192ebb56c4138b2952949a"
  },
  "json":null,
  "origin":"98.118.113.251",
  "url":"https://httpbin.org/post"
}`;

export default postFormDataResponse;
