/* eslint-disable no-useless-escape */

const parsingResponse = `{
  "status": 200,
  "is_billed": true,
  "credits": {
    "available": 0,
    "used": 0,
    "request_cost": 0
  },
  "data": [
    {
      "type": "xpath",
      "selector": "\/\/p",
      "results": [
        {
          "text": "This domain is for use in illustrative examples in documents. You may use this domain in literature without prior coordination or asking for permission."
        },
        {
          "text": "More information..."
        }
      ]
    },
    {
      "type": "css",
      "selector": "p",
      "results": [
        {
          "text": "This domain is for use in illustrative examples in documents. You may use this domain in literature without prior coordination or asking for permission."
        },
        {
          "text": "More information..."
        }
      ]
    }
  ],
  "html": ""
}`;

export default parsingResponse;
