/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeResponseBlock } from '../../components/code-block';

import blockCss from './code-blocks/block-css';
import blockImages from './code-blocks/block-images';
import blockUrl from './code-blocks/block-url';

const BlockingResources = () => {
  return (
    <section id="blocking-resources">
      <h2>Blocking Requests on Page</h2>
      <p>
        By default images, css, and fonts resources are blocked. To bypass this and make sure that all resources are allowed, set <span className="badge">block_resources</span> to false. This reduces your chances of being blocked and makes you look like a real user.
        
        However, if you want to allow or disallow certain resources, by using <span className="badge">reject_requests</span> you can easily
        block any resource of your choice on the page by specifying the file
        format. For example, you can block tracking scripts (.js files), images
        (.png, .jpg, or .jpeg files) or CSS (.css files)
      </p>

      <p>For example, to block all CSS on a page:</p>
      <CodeResponseBlock codeText={blockCss} />

      <p>And if you need to block any PNG and JPG image(s) on a page:</p>
      <CodeResponseBlock codeText={blockImages} />

      <p>If you need to block some particular URL on a page</p>

      <CodeResponseBlock codeText={blockUrl} />

      <div className="alert">
        <strong>Note:</strong> Blocking the requests only works when{' '}
        <span className="badge">render_js</span> is set to true
      </div>
    </section>
  );
};

export default BlockingResources;
