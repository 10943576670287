/** @jsx jsx */
import { jsx } from 'theme-ui';
import { CodeResponseBlock } from '../../components/code-block';

import usageEndpointResponse from './code-blocks/usage-endpoint-response';

const ResponseStatusCode = () => {
  return (
    <section id="response-status-code">
      <h2>API Response Status Codes</h2>

      <p>
        The response status code is set in the header as well as in the JSON
        object that you get in response.
      </p>

      <p>
        The <span className="badge">is_billed</span> attribute shows if the
        request was billed against credits in your account.
      </p>

      <h3>The ScrapeOwl API uses the following response status codes</h3>

      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>Response Code</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                200 <span className="badge">billed</span>
              </td>
              <td>Successful Call</td>
            </tr>
            <tr>
              <td>400</td>
              <td>Bad Request</td>
            </tr>
            <tr>
              <td>401</td>
              <td>
                Unauthorized - Your API key is wrong or you haven't provided one
              </td>
            </tr>
            <tr>
              <td>403</td>
              <td>
                Forbidden - You do not have enough credits left for this API
                call
              </td>
            </tr>
            <tr>
              <td>
                404 <span className="badge">billed</span>
              </td>
              <td>Page Not found</td>
            </tr>
            <tr>
              <td>429</td>
              <td>
                Too Many Concurrent Requests -- You consumed all of allowed
                concurrent requests. <br /> Please upgrade or contact support to
                increase the limit.
              </td>
            </tr>
            <tr>
              <td>500</td>
              <td>Internal Error, Please try again.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <p>
        If you would like to check the stats about credit usage in your account,
        send a GET request to the following endpoint:
      </p>

      <div className="alert">
        <strong>https://api.scrapeowl.com/v1/usage</strong>
      </div>

      <p>
        Requests to the usage endpoint are limited to <strong>20</strong>{' '}
        requests per minute.
      </p>

      <h3>The following JSON shows example response from /usage endpoint</h3>
      <CodeResponseBlock codeText={usageEndpointResponse} />
    </section>
  );
};

export default ResponseStatusCode;
