/** @jsx jsx */
import { jsx } from "theme-ui";
import { CodeResponseBlock } from "../../components/code-block";

import premiumProxies from "./code-blocks/premium-proxies";
import premiumProxiesLocation from "./code-blocks/premium-proxies-loction";

const Proxies = () => {
  return (
    <section id="proxies">
      <h2>Using Premium Proxies</h2>
      <p>
        By default, we use data center proxies to scrape the data from the web.
        But on some websites that could be difficult to scrape, you might need
        to switch to our premium proxies.
      </p>

      <p>
        Premium proxies use residential IP addresses so they evade detection as
        data center addresses.
      </p>

      <p>
        If you use premium proxies, then each request costs <strong>10</strong>{" "}
        credits.
      </p>

      <p>
        If you use premium proxies with <span className="badge">render_js</span>
        , then each request costs <strong>25</strong> credits.
      </p>

      <CodeResponseBlock codeText={premiumProxies} />

      <p>
        The default location of proxies is set to the USA by default. You can
        specify the country parameter to other countries to rotate or view
        localized versions as needed.
      </p>

      <CodeResponseBlock codeText={premiumProxiesLocation} />

      <p>The above example uses IP's from the UK.</p>
      <div className="alert">
        <strong>Note:</strong> Using{" "}
        <span className="badge">premium_proxies</span> costs between{" "}
        <strong>10</strong> or <strong>25</strong> credits per request depending
        on whether <span className="badge">render_js</span> is used or not.
      </div>

      <h3>List of countries with their ISO codes</h3>
      <div className="table-container">
        <table>
          <thead>
            <tr>
              <th>ISO code</th>
              <th>Name</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>br</td>
              <td>Brazil</td>
            </tr>
            <tr>
              <td>ca</td>
              <td>Canada</td>
            </tr>
            <tr>
              <td>fr</td>
              <td>France</td>
            </tr>
            <tr>
              <td>de</td>
              <td>Germany</td>
            </tr>
            <tr>
              <td>ge</td>
              <td>Greece</td>
            </tr>
            <tr>
              <td>il</td>
              <td>Israel</td>
            </tr>
            <tr>
              <td>in</td>
              <td>India</td>
            </tr>
            <tr>
              <td>it</td>
              <td>Italy</td>
            </tr>
            <tr>
              <td>mx</td>
              <td>Mexico</td>
            </tr>
            <tr>
              <td>nl</td>
              <td>Netherlands</td>
            </tr>
            <tr>
              <td>ru</td>
              <td>Russia</td>
            </tr>
            <tr>
              <td>es</td>
              <td>Spain</td>
            </tr>
            <tr>
              <td>se</td>
              <td>Sweden</td>
            </tr>
            <tr>
              <td>gb</td>
              <td>United Kingdom</td>
            </tr>
            <tr>
              <td>us</td>
              <td>United States</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Proxies;
