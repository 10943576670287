/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";

import Layout from "../components/layout";
import Introduction from "../sections/docs/introduction";
import GettingStarted from "../sections/docs/getting-started";
import MakingYourFirstRequest from "../sections/docs/making-your-first-request";
import Parsing from "../sections/docs/parsing";
import WaitingForElements from "../sections/docs/waiting-for-elements";
import RunningCustomJavascript from "../sections/docs/running-custom-javascript";
import HttpHeaders from "../sections/docs/http-headers";
import BlockingResources from "../sections/docs/blocking-resources";
import SendingPostRequests from "../sections/docs/sending-post-requests";
import Sessions from "../sections/docs/sessions";
import Proxies from "../sections/docs/proxies";
import ProxyMode from "../sections/docs/proxy-mode";
import RequestCalculation from "../sections/docs/request-calculation";
import ResponseStatusCodes from "../sections/docs/response-status-codes";
import Credits from "../sections/docs/credits";

const SIDEBAR_LIST = [
  {
    label: "Introduction",
    link: "#introduction",
  },
  {
    label: "Getting Started",
    link: "#getting-started",
  },
  {
    label: "Making your first request",
    link: "#making-your-first-request",
  },
  {
    label: "Parsing",
    link: "#parsing",
  },
  {
    label: "Waiting for elements",
    link: "#waiting-for-elements",
  },
  {
    label: "Running Custom Javascript",
    link: "#running-custom-javascript",
  },
  {
    label: "Http Headers",
    link: "#http-headers",
  },
  {
    label: "Blocking Resources",
    link: "#blocking-resources",
  },
  {
    label: "Sending Post Requests",
    link: "#sending-post-requests",
  },
  {
    label: "Sessions",
    link: "#sessions",
  },
  {
    label: "Proxies",
    link: "#proxies",
  },
  {
    label: "Proxy Mode",
    link: "#proxy-mode",
  },
  {
    label: "Request Calculation",
    link: "#request-calculation",
  },
  {
    label: "Response Status Code",
    link: "#response-status-code",
  },
  {
    label: "Credits",
    link: "#credits",
  },
];

const Docs = () => {
  // const sidebarListRef = useRef(SIDEBAR_LIST.map(() => createRef()));

  const options = {
    threshold: 0,
    rootMargin: "-50% 0px",
  };

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        const id = entry.target.getAttribute("id");

        if (entry.isIntersecting) {
          document
            .querySelector(`.sidebarList li a[href="#${id}"]`)
            .classList.add("active");
        } else {
          document
            .querySelector(`.sidebarList li a[href="#${id}"]`)
            .classList.remove("active");
        }
      });
    }, options);

    document.querySelectorAll("section[id]").forEach((section) => {
      observer.observe(section);
    });

    return () => observer.disconnect();
  });

  return (
    <Layout>
      <div sx={styles.container}>
        <aside sx={styles.sidebar}>
          <div sx={styles.sidebarContainer}>
            <h2 sx={styles.sidebarHeading}>API Docs</h2>
            <ul sx={styles.sidebarList} className="sidebarList">
              {SIDEBAR_LIST.map((list) => {
                return (
                  <li>
                    <a href={list.link}>{list.label}</a>
                  </li>
                );
              })}
            </ul>
          </div>
        </aside>
        <article sx={styles.docsContent}>
          <Introduction />
          <GettingStarted />
          <MakingYourFirstRequest />
          <Parsing />
          <WaitingForElements />
          <RunningCustomJavascript />
          <HttpHeaders />
          <BlockingResources />
          <SendingPostRequests />
          <Sessions />
          <Proxies />
          <ProxyMode />
          <RequestCalculation />
          <ResponseStatusCodes />
          <Credits />
        </article>
      </div>
    </Layout>
  );
};

export default Docs;

const styles = {
  container: {
    variant: "layout.container",
    display: "flex",
    flexDirection: ["column", "row"],
    justifyContent: "space-between",
    mt: 4,
    mb: 6,
    pt: 5,
  },

  sidebarContainer: {
    position: "sticky",
    top: "5%",
    flex: "1 1 15rem",
    mr: 4,
    mb: 4,
  },

  sidebarHeading: {
    fontSize: ".8rem",
    fontWeight: 600,
    opacity: 0.8,
    textTransform: "uppercase",
    px: 3,
    py: 2,
    color: "#6b7280",
  },

  sidebarList: {
    mt: 2,

    li: {
      mt: 1,
    },

    a: {
      display: "block",
      fontSize: ".9rem",
      py: "6px",
      px: 3,
      fontWeight: 600,
      transition: "all .05s ease-in-out",
      borderRadius: "5px",
      color: "grey",
    },

    "a:hover": {
      bg: "gray",
      opacity: 0.8,
      transition: "all .05s ease-in-out",
    },

    "a.active": {
      bg: "purple",
      color: "white",
    },
  },

  docsContent: {
    flex: "0 84ch",
    maxWidth: "55rem",
    wordWrap: "break-word",
    border: "2px solid #e8e8e8",
    borderRadius: "8px",
    px: [3, 4, 5],
    py: [3, 4],

    h2: {
      fontWeight: "bold",
      fontSize: [3, 4, 5],
      color: "purplegray",
      pb: 2,
      mt: [4, 5],
      mb: [2, 3],
      borderBottom: "1px solid #efefef",
    },

    h3: {
      fontWeight: 600,
      fontSize: [2, 3],
      color: "purplegray",
      mt: 4,
      mb: 2,
    },

    p: {
      color: "rgba(107, 114, 128, 1)",
      mt: 3,
    },

    a: {
      color: "#777DF2",
      fontWeight: "bold",
    },

    "a:hover": {
      textDecoration: "underline",
    },

    code: {
      mt: 2,
      display: "inline-block",
      color: "#ff8b49",
    },

    strong: {
      fontWeight: "bold",
      color: "purplegray",
      mt: 1,
      mb: 2,
      display: "inline-block",
    },

    ".alert": {
      p: ".8rem",
      bg: "#F0F0FD",
      borderLeft: "5px solid",
      borderColor: "purplegray",
      my: 3,
      fontSize: ".9rem",

      ".badge": {
        bg: "rgba(0,0,0,.07)",
      },

      strong: {
        display: "inline",
      },
    },

    ".badge": {
      color: "purplegray",
      bg: "#F0F0FD",
      px: 2,
      py: 1,
      fontSize: ".8rem",
      fontWeight: "bold",
      borderRadius: "8px",
    },

    table: {
      color: "#6b7280",
      borderCollapse: "collapse",
      fontSize: ".85rem",
      borderSpacing: "2px",
      border: "none",
      width: "100%",
    },

    ".table-container": {
      border: "1px solid transparent",
      borderRadius: "10px",
      my: 3,
    },

    "tbody tr:nth-of-type(odd)": {
      bg: "hsla(0,0%,65.5%,.05)",
    },

    "tbody tr:hover": {
      color: "#212529",
      bg: "rgba(0,0,0,.07)",
    },

    th: {
      textTransform: "uppercase",
      fontWeight: 400,
      textAlign: "left",
      border: "1px solid #ececec",
      p: ".75rem",
    },

    td: {
      p: ".75rem",
      border: "1px solid #dee2e6",
    },
  },
};
