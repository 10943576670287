const proxyMode = [
  {
    lang: 'basic',
    format: 'curl',
    code: `http://scrapeowl:YOUR_API_KEY@proxy.scrapeowl.com:9000`,
  },
  {
    lang: 'render_js',
    format: 'curl',
    code: `http://scrapeowl:YOUR_API_KEY:render_js=true@proxy.scrapeowl.com:9000`,
  },
  {
    lang: 'premium_proxies',
    format: 'curl',
    code: `http://scrapeowl:YOUR_API_KEY:premium_proxies=true@proxy.scrapeowl.com:9000`,
  },
  {
    lang: 'country_selection',
    format: 'curl',
    code: `http://scrapeowl:YOUR_API_KEY:premium_proxies=true:country=us@proxy.scrapeowl.com:9000`,
  },
];

export default proxyMode;
