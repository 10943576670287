/** @jsx jsx */
import { jsx } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

const Introduction = () => {
  return (
    <section id="introduction">
      <h2>Introduction</h2>

      <div>
        <h3>What is the ScrapeOwl API?</h3>
        <p>ScrapeOwl is a simple web scraping and data extraction API.</p>
      </div>

      <div>
        <h3>How does it work?</h3>
        <p>
          ScrapeOwl's API allows you to send requests specifying the website and
          its elements you want to scrape.
        </p>
        <div>
          <StaticImage
            src="../../assets/images/data-extraction.svg"
            alt="Data Extraction"
          />
        </div>
      </div>

      <div>
        <h3>Example</h3>
        <p>
          For example, if you want the content of the h1 and p tags from a
          series of articles on example.com where the h1 tag contains the title
          of the article and the p tags contain the body text.
        </p>

        <p>
          Using ScrapeOwl’s API, you specify example.com as the URL you would
          like to scrape, and h1 and p as the elements you would like to parse
          and retrieve content from.
        </p>
      </div>
    </section>
  );
};

export default Introduction;
